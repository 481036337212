import { VITE_API_LEVELPLANE } from 'src/config';
import AuthAxios from '../auth-axios';
import { APIListNoCount } from 'src/types/api';
import { Notification } from 'src/types/notifications';


class NotificationsAPI extends AuthAxios {
  constructor() {
    super({ baseUrl: VITE_API_LEVELPLANE });
  }

  async getNotifications(): Promise<APIListNoCount<Notification>> {
    try {
      const { data } = await this.axiosInstance.get<APIListNoCount<Notification>>(
        `/notifications`
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
      # Mark a notification as read
      PATCH "${HOST}/v1/notifications/{notification_id}"
      Authorization: Bearer ${TOKEN}
      Content-Type: application/json
      {
          "status": "read"
      }


      # Delete a notification
      DELETE "${HOST}/v1/notifications/{notification_id}"
      Authorization: Bearer ${TOKEN}
   */
  
}

export const notificationsAPI = new NotificationsAPI();
